"use client"

import * as Sentry from "@sentry/nextjs"
import { useEffect } from "react"
import { ErrorPageComponent } from "./(tailwind)/Projects/[parsid]/(components)/errorPage"
import { ThemeProvider } from "./components/theme-provider"
import Header from "./login/components/header"

export default function GlobalError({
  error,
  reset,
  children,
}: {
  readonly error: Error & { digest?: string }
  readonly reset: () => void
  readonly children: React.ReactNode
}) {
  useEffect(() => {
    Sentry.captureException(error)
  }, [error])

  return (
    <html lang="en">
      <body>
        <ThemeProvider attribute="class" defaultTheme="system" enableSystem>
          <Header />
          <div className="flex h-full flex-col items-center justify-center">
            <ErrorPageComponent error={error} reset={reset} />
          </div>
          <div className="flex h-full grow flex-col">{children}</div>
        </ThemeProvider>
      </body>
    </html>
  )
}
